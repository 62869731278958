import { SITE_LOCALE } from 'app-constants';

import { BaseSEO } from 'stile-shared/src/gatsby/BaseSEO';

import { getPageData } from 'utils/getPageData';

export function SEO({
  slug,
  ...props
}: { slug: string } & Partial<React.ComponentProps<typeof BaseSEO>>) {
  const { seo } = getPageData(slug);

  return BaseSEO({
    locale: SITE_LOCALE,
    slug,
    ...seo,
    ...props,
  });
}
