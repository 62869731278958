import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Lead, PageHeading, Section } from '../../common/Primitives';
import { Box } from '../../layout/Box';

export type HeroBlockProps = {
  title: string;
  text?: string;
  image?: React.ReactNode;
  media?: React.ReactNode;
  variant?: 'standard' | 'tight';
};

/**
 * Use loading="eager" on image element!!
 * <StaticImage loading="eager" src="./page-hero.jpg" alt="Descriptive text" aspectRatio={2.37} />
 */
export function HeroBlock({ title, text, image, media, variant = 'standard' }: HeroBlockProps) {
  return (
    <Section space={0}>
      <Info variant={variant}>
        <PageHeading>{title}</PageHeading>
        {text && <Lead as="h2">{text}</Lead>}
      </Info>
      {image && <ImageWrapper>{image}</ImageWrapper>}
      {media && <Box>{media}</Box>}
    </Section>
  );
}

const Info = styled.div<{ variant?: 'standard' | 'tight' }>`
  margin-top: ${theme.space.lToXl};
  margin-bottom: ${theme.space.lToXl};
  padding-left: ${theme.space.gutters};
  padding-right: ${theme.space.gutters};

  && > * {
    max-width: calc(${theme.space.siteWidth} * 0.7);
  }

  @media ${theme.media.min.m} {
    margin-top: 5rem;
    margin-bottom: ${(props) => (props.variant === 'tight' ? '2.5rem' : '5rem')};
    padding-left: ${theme.space.xxlToXxxl};
    padding-right: ${theme.space.xxlToXxxl};
  }

  @media ${theme.media.min.l} {
    margin-top: 10rem;
    margin-bottom: ${(props) => (props.variant === 'tight' ? '5rem' : '10rem')};
    padding-left: ${theme.space.xxlToXxxl};
    padding-right: ${theme.space.xxlToXxxl};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 ${theme.space.gutters};

  & > * {
    width: 100%;
  }

  @media (max-width: ${theme.space.siteWidth}) {
    padding: 0;

    && img {
      border-radius: 0;
    }
  }
`;
