import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { H2, Section } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { FullWidthBackground } from '../../layout/FullWidthBackground';

type TextBlockVariant = 'inherit' | 'dark' | 'beige';

type TextBlockProps = React.ComponentProps<typeof Section> &
  Pick<React.ComponentProps<typeof Center>, 'textAlign'> &
  Pick<React.ComponentProps<typeof Column>, 'space'> & {
    title?: string;
    variant?: TextBlockVariant;
    media?: React.ReactNode;
  };

export function TextBlock({
  variant = 'inherit',
  stretch = false,
  title,
  textAlign,
  space,
  media,
  children,
  ...props
}: React.PropsWithChildren<TextBlockProps>) {
  let containerProps: React.ComponentProps<typeof FullWidthBackground> = {};

  if (variant === 'beige') {
    containerProps = { secondaryColor: theme.colors.beige };
  } else if (variant === 'dark') {
    containerProps = { invertColors: true };
  }

  const content = (
    <Column space={theme.space.section} alignSelf="stretch">
      <Center max={theme.space.measure} textAlign={textAlign} stretch={stretch}>
        <Column space={space}>
          {title && <H2>{title}</H2>}
          {children}
        </Column>
      </Center>

      {media}
    </Column>
  );

  if (variant !== 'inherit') {
    return (
      <FullWidthBackground forwardedAs="section" {...containerProps}>
        {content}
      </FullWidthBackground>
    );
  }

  return <Section {...props}>{content}</Section>;
}
