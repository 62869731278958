import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Box } from '../../layout/Box';

export type BubbleProps = React.ComponentProps<typeof Box>;

export function Bubble(props: BubbleProps) {
  return (
    <BubbleContainer
      forwardedAs="section"
      secondaryColor={theme.colors.beige}
      space={[theme.space.section, theme.space.l]}
      usePadding
      {...props}
    />
  );
}

export const BubbleContainer = styled(Box)<BubbleProps>`
  border-radius: ${theme.borderRadius.l};
  margin: ${theme.space.section} ${theme.space.gutters};
`;
