// Not in stile-shared since it uses local getPageData
import * as React from 'react';

import { PageLinksBlock } from 'stile-shared/src/components/2020/blocks/PageLinksBlock';
import { PAGE_SECTIONS } from 'stile-shared/src/constants';

import { getPageData } from 'utils/getPageData';

type NextPageBlockProps = {
  path: string;
};

export function NextPageBlock({ path }: NextPageBlockProps) {
  const props = {
    subtitle: PAGE_SECTIONS[path.split('/')[1]],
    ...getPageData(path).hero,
    button: { url: path },
  };

  return <PageLinksBlock {...props} />;
}
