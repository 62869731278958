import * as React from 'react';

import { CtaBlock as SharedCtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { TrialButton } from 'stile-shared/src/components/2020/common/TrialButton';

export function CtaBlock(props: React.ComponentProps<typeof SharedCtaBlock>) {
  return (
    <SharedCtaBlock
      {...props}
      title="Join a vibrant community of passionate science educators"
      text="Thousands of teachers use Stile every week. It’s already the #1 science curriculum in Australia, and it’s fast becoming the curriculum of choice for teachers in the US."
      button={
        <TrialButton variant="dark" gtagEvent="enquire_now_us">
          Connect with a Curriculum Specialist
        </TrialButton>
      }
    />
  );
}
