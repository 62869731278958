import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P, Text } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function HiringProcessPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/hiring-process/cover_hiring_process.png"
            alt="A software engineer at a workstation with a laptop and a wide external monitor"
          />
        }
      />

      <TextBlock>
        <P>
          We’ve got pretty high standards here at Stile. We aspire to build a team of exceptional
          individuals who are a delight to work with every day. And while there’s no question that a
          strong sense of purpose makes for a rewarding job, we firmly believe that a team of
          outstanding co-workers has the power to transform that rewarding job into a genuine joy.
        </P>

        <P>
          Building such a team is a perpetual pursuit, and it begins with hiring. As such, you’ll
          find our process unusually thorough. We don’t believe in gimmicks or secret tests, but
          through the process, we will get to know you very well, and you’ll get to know us too. We
          do everything we can to make sure we don’t put someone in a job that we don’t think
          they’re capable of smashing out of the park (given time and the right mentoring). Equally,
          we know Stile won’t be the right fit for everyone, and as such, our process is designed to
          give you the time and insight needed to make that call for yourself.
        </P>

        <P>
          We want Stile to be an inclusive workplace. The work is often demanding, and the
          expectations always high, but that need not come at the expense of more important
          priorities, especially family. Further, we appreciate that people from different walks of
          life bring unique perspectives, and it is these combined perspectives that help us deliver
          the best possible education to our customers.
        </P>

        <P>
          It is very important to us that we hire the right person for each role. We also like to
          remind each applicant that the interviewing process at Stile is a two-way street. Not only
          are we trying to find the right person for each role, but we want to make sure that Stile
          is the right fit for you.
        </P>
      </TextBlock>

      <TextBlock variant="dark" title="The process" space={theme.space.m}>
        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step One: The initial meeting
          </Text>
          <P>
            You’ve obviously made a great first impression and we’re keen to meet. You’ll be meeting
            with our People & Culture Team as well as your potential manager. We’ll tell you more
            about Stile and the role and get to know you a little better. Expect that this will take
            about 30-45 minutes of your time. We often conduct these meetings via video conference.
          </P>
        </Column>

        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step Two: The task
          </Text>
          <P>
            You’ll be asked to complete a task that will assess key skills required for the
            particular position you’ve applied for. Then you will be given the opportunity to
            explain the process you went through in a follow-up meeting. Each role will have a
            different task and time frame, so we’ll let you know how much time to expect to carve
            out of your day for this step.
          </P>
        </Column>

        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step Three: The career chat
          </Text>
          <P>
            In this step, you will be meeting with two members of our Executive team. In this
            interview, we will ask about your education and entire career – some of your successes,
            mistakes, key decisions and key relationships. This interview normally takes about 3
            hours.
          </P>
        </Column>

        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step Four: The reference checks
          </Text>
          <P>
            If you’re successful and still interested in joining the Stile team, we will ask you to
            arrange personal reference calls with former managers and others.
          </P>
        </Column>

        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step Five: Onboarding
          </Text>
          <P>
            We’re so excited to have you start at Stile! During this time, we’ll ensure that you
            have the proper tools to get settled in with us and do an ace job. We’ll also formulate
            a professional development plan to help you grow.
          </P>
        </Column>

        <Column space={theme.space.xxs}>
          <Text as="h3" bold>
            Step Six: Check-ins
          </Text>
          <P>
            We provide check-ins after 3 and 6 months, which are crucial for your development. As
            always, Stile operates as a two-way street, so you’ll always be able to give us feedback
            so we can both equally develop and evolve!
          </P>
        </Column>
      </TextBlock>

      <TextBlock>
        <P>
          We have a pretty good idea of who you are by the time you start with us, but there’s
          nothing like actually working with you. We use your first 6 months on the team to really
          get an understanding of whether we think you’re going to succeed at Stile in the long
          term.
        </P>

        <P>
          We use all of our notes during the interview process along with the job scorecard we’ve
          written for this position to help guide you to success at Stile.
        </P>
      </TextBlock>

      <NextPageBlock path="/who-we-are/join-the-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default HiringProcessPage;
