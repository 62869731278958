import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Bubble } from '../../common/Bubble';
import { ButtonLink } from '../../common/ButtonLink';
import { Heading, P, Text } from '../../common/Primitives';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';

export type PageLinksBlockProps = React.ComponentProps<typeof Bubble> & {
  title: string;
  subtitle?: string;
  text: string;
  button: { url: string; text?: string; openInNewTab?: boolean };
};

export function PageLinksBlock({ title, subtitle, text, button, ...props }: PageLinksBlockProps) {
  return (
    <Bubble {...props}>
      <Clamp max="650px">
        <Column space={theme.space.xxxs}>
          {subtitle && (
            <Text as="h2" size="m" semiBold>
              {subtitle}
            </Text>
          )}

          <Column space={theme.space.l}>
            <Column space={theme.space.xxs}>
              <Heading as="h3" size="xxl">
                {title}
              </Heading>
              <P>{text}</P>
            </Column>

            <ButtonLink variant="cta" url={button.url} openInNewTab={button.openInNewTab}>
              {button.text ?? 'Learn more →'}
            </ButtonLink>
          </Column>
        </Column>
      </Clamp>
    </Bubble>
  );
}
