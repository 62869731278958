import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Heading, P } from '../../common/Primitives';
import { TrialButton } from '../../common/TrialButton';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';
import { FullWidthBackground } from '../../layout/FullWidthBackground';

type CtaBlockProps = React.ComponentProps<typeof FullWidthBackground> & {
  title?: string;
  text?: string;
  button?: React.ReactNode;
};

export function CtaBlock({
  title = 'Get Stile at your school',
  text = 'Join a community of schools passionate about great science education.',
  button = <TrialButton variant="dark">Get in touch</TrialButton>,
  ...props
}: CtaBlockProps) {
  return (
    <FullWidthBackground
      forwardedAs="section"
      textAlign="center"
      primaryColor={theme.colors.dark}
      secondaryColor={theme.colors.brand}
      {...props}
    >
      <Column>
        <Heading as="h2" size="xxl">
          {title}
        </Heading>

        {text && <P>{text}</P>}

        {button && <Center>{button}</Center>}
      </Column>
    </FullWidthBackground>
  );
}
